import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectedProjectSelector } from '@selectors';

import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import useDispatch from '@hooks/useDispatch';
import { ProjectType } from '@/types/Project';
import { deleteFile, deleteFolder } from '@slices/fileSlices';
import { EditIcon, PlusSquareIcon } from '@chakra-ui/icons';
import File from '@projectTypes/File';

import { HiDotsHorizontal } from 'react-icons/hi';
import { BiPlusCircle, BiTrash } from 'react-icons/bi';
import { useConfirmDelete } from 'chakra-confirm';
import FileCreateModal from './FileCreateModal';
import ImageUploadModal from './ImageUploadModal';
import { MdFileUpload } from 'react-icons/md';

export enum FType {
  File = 'file',
  Folder = 'folder',
}

interface FileOptionProps {
  currentFile?: File;
  path: string;
}

const FileOptionsMenu = (props: FileOptionProps) => {
  const { currentFile, path } = props;
  const selectedProject = useSelector(selectedProjectSelector);
  const dispatch = useDispatch();
  const [showImageModal, setShowImageModal] = useState(false);
  const [fileCreateTypeState, setFileCreateTypeState] = useState(FType.File);
  const [fileRenameState, setFileRenameState] = useState(false);
  const [fileCreateState, setFileCreateState] = useState(false);
  const fileEditingAllowed = selectedProject
    ? selectedProject.type === ProjectType.WEB
    : false;
  const isFolder = currentFile.name.split('.').length === 1;

  const confirmDelete = useConfirmDelete({
    title: 'Are you sure you want to delete this file',
    body: 'All data will be lost',
  });

  const deleteFileAction = async (id: any) => {
    const ok = await confirmDelete();
    if (ok) {
      dispatch(deleteFile(id));
    }
  };
  const deleteFolderAction = async (fileId: string) => {
    const ok = await confirmDelete();

    if (ok) {
      dispatch(deleteFolder(fileId));
    }
  };

  const onCloseModal = () => {
    setShowImageModal(false);
    setFileCreateState(false);
  };

  return (
    <>
      {fileEditingAllowed && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HiDotsHorizontal color="white" />}
            height={'24px'}
            width={'10px'}
            backgroundColor={'transparent'}
            textColor={'white'}
            alignItems={'center'}
            marginLeft={'2'}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />

          <MenuList
            textColor={'white'}
            fontSize={'13px'}
            backgroundColor={'#181818'}
            marginTop={'-2'}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {isFolder && (
              <MenuItem
                backgroundColor={'#181818'}
                textColor={'white'}
                icon={<PlusSquareIcon />}
                _hover={{ background: '#2b2d2e' }}
                onClick={() => {
                  setFileRenameState(false);
                  setFileCreateState(true);
                  setFileCreateTypeState(FType.File);
                }}
              >
                New File
              </MenuItem>
            )}
            <MenuItem
              backgroundColor={'#181818'}
              _hover={{ background: '#2b2d2e' }}
              icon={<BiTrash />}
              onClick={() => {
                isFolder
                  ? deleteFolderAction(currentFile.id)
                  : deleteFileAction(currentFile.id);
              }}
            >
              Delete File
            </MenuItem>
            {isFolder && (
              <MenuItem
                backgroundColor={'#181818'}
                _hover={{ background: '#2b2d2e' }}
                icon={<BiPlusCircle />}
                onClick={() => {
                  setFileRenameState(false);
                  setFileCreateState(true);
                  setFileCreateTypeState(FType.Folder);
                }}
              >
                Create Folder
              </MenuItem>
            )}

            <MenuItem
              backgroundColor={'#181818'}
              _hover={{ background: '#2b2d2e' }}
              icon={<EditIcon />}
              onClick={() => {
                setFileCreateTypeState(isFolder ? FType.Folder : FType.File);
                setFileRenameState(true);
                setFileCreateState(true);
              }}
            >
              Rename File
            </MenuItem>
            {isFolder && (
              <MenuItem
                icon={<MdFileUpload />}
                backgroundColor="transparent"
                onClick={() => {
                  setShowImageModal(true);
                }}
              >
                Upload Image
              </MenuItem>
            )}
          </MenuList>
        </Menu>
      )}

      <FileCreateModal
        state={fileCreateState}
        folder={false}
        onClose={onCloseModal}
        path={path}
        createType={fileCreateTypeState}
        selectedProject={selectedProject}
        currentFile={currentFile}
        rename={fileRenameState}
      />

      <ImageUploadModal
        state={showImageModal}
        onClose={onCloseModal}
        path={path}
        displayedFile={currentFile}
      />
    </>
  );
};

export default FileOptionsMenu;
