import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import File from '@projectTypes/File';
import { selectedProjectSelector } from '@selectors';

import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { EditIcon, PlusSquareIcon } from '@chakra-ui/icons';
import useDispatch from '@hooks/useDispatch';
import FileCreateModal from './FileCreateModal';
import {
  decreaseFontSize,
  increaseFontSize,
  resetFontSize,
} from '@slices/globalSlices';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { BiReset } from 'react-icons/bi';
import { MdFileUpload } from 'react-icons/md';
import ImageUploadModal from './ImageUploadModal';
import { ProjectType } from '@projectTypes/Project';
import { FType } from './FileOptionsMenu';
interface MainMenuProps {
  currentFile?: File;
  path: string;
  fileStructure?: Map<string, string>;
  dim?: string;
}

const MainMenu = (props: MainMenuProps) => {
  const { currentFile, dim } = props;
  const selectedProject = useSelector(selectedProjectSelector);
  const dispatch = useDispatch();
  const [fileCreateTypeState, setFileCreateTypeState] = useState(FType.File);
  const [fileRenameState, setFileRenameState] = useState(false);
  const [fileCreateState, setFileCreateState] = useState(false);
  const [imageUploadState, setImageUploadState] = useState(false);
  const fileEditingAllowed = selectedProject
    ? selectedProject.type === ProjectType.WEB
    : false;

  const onCloseModal = () => {
    setImageUploadState(false);
    setFileCreateState(false);
  };

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HiDotsHorizontal color="white" size={'70%'} />}
          height={'32px'}
          width={!!dim ? dim : '10px'}
          backgroundColor={'transparent'}
          textColor={'white'}
          alignItems={'center'}
          marginLeft={'2'}
          onClick={(e) => {}}
        />

        <MenuList
          textColor={'white'}
          fontSize={'13px'}
          backgroundColor={'#181818'}
          marginTop={'-2'}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {fileEditingAllowed && (
            <>
              {' '}
              <MenuItem
                backgroundColor={'#181818'}
                textColor={'white'}
                icon={<PlusSquareIcon />}
                _hover={{ background: '#2b2d2e' }}
                onClick={() => {
                  setFileRenameState(false);
                  setFileCreateState(true);
                  setFileCreateTypeState(FType.File);
                }}
              >
                New File
              </MenuItem>
              <MenuItem
                backgroundColor={'#181818'}
                _hover={{ background: '#2b2d2e' }}
                icon={<EditIcon />}
                onClick={() => {
                  setFileRenameState(false);
                  setFileCreateState(true);
                  setFileCreateTypeState(FType.Folder);
                }}
              >
                Create Folder
              </MenuItem>
              <MenuItem
                backgroundColor={'#181818'}
                _hover={{ background: '#2b2d2e' }}
                icon={<MdFileUpload />}
                onClick={() => {
                  setImageUploadState(true);
                }}
                closeOnSelect={false}
              >
                Image Upload
              </MenuItem>
            </>
          )}
          <MenuItem
            backgroundColor={'#181818'}
            _hover={{ background: '#2b2d2e' }}
            icon={<FaPlus />}
            onClick={(e) => {
              dispatch(increaseFontSize());
            }}
            closeOnSelect={false}
          >
            Increase font size
          </MenuItem>
          <MenuItem
            backgroundColor={'#181818'}
            _hover={{ background: '#2b2d2e' }}
            icon={<BiReset />}
            onClick={() => {
              dispatch(resetFontSize());
            }}
            closeOnSelect={false}
          >
            Reset font size
          </MenuItem>
          <MenuItem
            backgroundColor={'#181818'}
            _hover={{ background: '#2b2d2e' }}
            icon={<FaMinus />}
            onClick={() => {
              dispatch(decreaseFontSize());
            }}
            closeOnSelect={false}
          >
            Decrease font size
          </MenuItem>
        </MenuList>
      </Menu>

      <ImageUploadModal
        displayedFile={currentFile}
        state={imageUploadState}
        onClose={onCloseModal}
      />

      <FileCreateModal
        state={fileCreateState}
        folder={false}
        onClose={onCloseModal}
        path={''}
        createType={fileCreateTypeState}
        selectedProject={selectedProject}
        currentFile={currentFile}
        rename={fileRenameState}
      />
    </>
  );
};

export default MainMenu;
